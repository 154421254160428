import { FiltersModel } from "@/core/models/filters";

export enum SuppliersFiltersItem {
  RISK_LEVEL = "risk_level",
  CATEGORY = "category",
  EXPOSURE = "exposure",
  ACTUAL_RATING = "actual_rating",
  BUSINESS_UNITS = "business_units",
}

export class SuppliersFiltersModel extends FiltersModel {
  public [SuppliersFiltersItem.RISK_LEVEL]: string | null;
  public [SuppliersFiltersItem.CATEGORY]: string | null;
  public [SuppliersFiltersItem.EXPOSURE]: string | null;
  public [SuppliersFiltersItem.ACTUAL_RATING]: number | string | null;
  public [SuppliersFiltersItem.BUSINESS_UNITS]: string | null;

  constructor(query: any) {
    super();
    this[SuppliersFiltersItem.RISK_LEVEL] =
      query[SuppliersFiltersItem.RISK_LEVEL] || null;
    this[SuppliersFiltersItem.CATEGORY] =
      query[SuppliersFiltersItem.CATEGORY] || null;
    this[SuppliersFiltersItem.EXPOSURE] =
      query[SuppliersFiltersItem.EXPOSURE] || null;
    this[SuppliersFiltersItem.ACTUAL_RATING] =
      query[SuppliersFiltersItem.ACTUAL_RATING] || null;
    this[SuppliersFiltersItem.BUSINESS_UNITS] =
      query[SuppliersFiltersItem.BUSINESS_UNITS] || null;
    this.initAttrsFromQuery(query);
  }

  setStatusFilter(filter: string, value: any) {
    const statusFilters = [
      SuppliersFiltersItem.ACTUAL_RATING,
    ];
    statusFilters.forEach((statusFilter) => {
      if (statusFilter === filter) {
        this[statusFilter] = value;
      } else {
        this[statusFilter] = null;
      }
    });
  }
}
