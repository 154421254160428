
import { defineComponent, computed, ref, watch, nextTick } from "vue";

export default defineComponent({
  name: "blocks-node",
  props: {
    level: {
      type: Number,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    props: {
      type: Object,
      default: () => ({
        label: "label",
        expand: "expand",
        children: "children",
        key: "id",
      }),
    },
    activeBusinessUnit: Number,
    activeBusinessSubunit: Number,
    collapsable: {
      type: Boolean,
      default: false,
    },
    renderContent: Function,
    labelClassName: [String, Function],
  },
  setup(props, { emit, expose }) {
    watch(
      () => props,
      async (val: any) => {
        if (val.data.business_unit?.id === props.activeBusinessSubunit) {
          await nextTick();
          const element: any = document.querySelector(
            `#org-tree-node-children-${val.data.business_unit.id}`
          );
          element.style.translate = "0 0";
          await nextTick();
          element!.style.translate = getTranslate(element);
        }
        if (
          val.data.business_unit?.id === props.activeBusinessUnit &&
          !props.activeBusinessSubunit
        ) {
          await nextTick();
          const element: any = document.querySelector(
            `#org-tree-node-children-${val.data.business_unit.id}`
          );
          element.style.translate = "0 0";
          await nextTick();
          element!.style.translate = getTranslate(element);
        }
      },
      {
        deep: true,
      }
    );



    const blockNodeRef = ref<HTMLElement>();
    let isLeaf = computed(() =>
      Array.isArray(props.data[props.props.children]) &&
      props.data[props.props.children].length > 0
        ? false
        : true
    );

    let expanded = ref<boolean>(props.data[props.props.expand] && true);

    let nodeClass = computed(() => {
      return {
        "org-tree-node": true,
        "is-leaf": isLeaf.value,
        collapsed: !isLeaf.value && props.collapsable && !expanded.value,
      };
    });

    let innerLabelClass = computed(() => {
      let labelClassName =
        typeof props.labelClassName == "function"
          ? props.labelClassName(props.data)
          : props.labelClassName;

      return {
        "org-tree-node-label-inner": true,
        [labelClassName]: !!labelClassName,
      };
    });
    let nodeExpandBtnClass = computed(() => {
      return {
        "org-tree-node-btn": true,
        expanded: !!expanded.value,
      };
    });
    const toggleExpand = () => {
      expanded.value = !expanded.value;
    };
    const onExpandBtnClick = (e: Event) => {
      toggleExpand();
      emit("node-expand", e, props.data, nodeContext);
    };
    const nodeContext = {
      isExpanded: () => expanded.value,
      toggleExpand,
    };

    const nodeKey = (child: any) => {
      var key = child[props.props.key];
      if (typeof key === "function") {
        return key(child);
      }
      return key;
    };
    const enter = (event: any) => {
      const element = event;
      element.style.visibility = "hidden";
      element.style.height = "auto";
      const { height } = getComputedStyle(element);
      element.style.visibility = null;
      element.style.height = 0;
      setTimeout(() => {
        element.style.height = height;
      });
    };
    const afterEnter = (event: any) => {
      const element = event;
      element.style.height = "auto";
      element.style.translate = getTranslate(element);
    };
    const leave = (event: any) => {
      const element = event;
      const { height } = getComputedStyle(element);
      element.style.height = height;
      setTimeout(() => {
        element.style.height = 0;
      });
    };

    const getTranslate = (el: any) => {
      if (document.querySelector("#tree-wrapper")) {
        document.querySelector<HTMLElement>(
          "#tree-wrapper"
        )!.style.height = `auto`;
      }
      const defaultHeight = 635;
      const rootParentList =
        props.level === 2
          ? el.parentElement.parentElement
          : el.parentElement.parentElement.parentElement.parentElement;
      const parentList = el.parentElement.parentElement;

      const parentHeight = parentList.offsetHeight;
      const rootParentListHeight = rootParentList.offsetHeight;
      const baseHeight = Math.max(
        parentHeight,
        rootParentListHeight,
        defaultHeight
      );

      const headerHeight = 80;
      const padding = 32;
      const openedElementHeight = el.offsetHeight;
      const openedElementHalfHeight = openedElementHeight / 2;
      const openedElementOffsetFromTop =
        el.getBoundingClientRect().top +
        window.pageYOffset -
        headerHeight -
        padding;

      if (document.querySelector("#tree-wrapper")) {
        document.querySelector<HTMLElement>("#tree-wrapper")!.style.height = `${
          baseHeight > openedElementHeight ? baseHeight : openedElementHeight
        }px`;
      }

      if (openedElementHeight > baseHeight) {
        return `0 -${openedElementOffsetFromTop}px`;
      }

      if (baseHeight - openedElementOffsetFromTop < openedElementHalfHeight) {
        return `0 -${
          openedElementHeight - (baseHeight - openedElementOffsetFromTop)
        }px`;
      }

      if (openedElementHalfHeight < openedElementOffsetFromTop) {
        return `0 -${openedElementHalfHeight}px`;
      } else {
        return `0 -${openedElementOffsetFromTop}px`;
      }
    };

    return {
      enter,
      afterEnter,
      leave,
      nodeClass,
      innerLabelClass,
      isLeaf,
      nodeExpandBtnClass,
      onExpandBtnClick,
      expanded,
      nodeContext,
      nodeKey,
      blockNodeRef,
      getTranslate,
    };
  },
});
