
import BlocksNode from "./BlocksNode.vue";
import { defineComponent, ref } from "vue";
interface PropsType {
  label: string;
  expand: string;
  children: string;
  key?: any | (() => any);
}
export default defineComponent({
  name: "blocks-tree",
  components: {
    BlocksNode,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
    props: {
      type: Object,
      default: () => {
        return {
          label: "label",
          expand: "expand",
          children: "children",
          key: "id",
        } as PropsType;
      },
    },
    horizontal: Boolean,
    activeBusinessUnit: {
      required: false,
      type: Number,
    },
    activeBusinessSubunit: {
      required: false,
      type: Number,
    },
    collapsable: Boolean,
    renderContent: Function,
    labelWidth: [String, Number],
    labelClassName: [Function, String],
  },
  setup() {
    const blockNodeRef = ref<HTMLElement>();

    return {
      blockNodeRef,
    };
  },
});
