import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, Transition as _Transition, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-66b3ce93"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_blocks_node = _resolveComponent("blocks-node", true)!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.nodeClass)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["org-tree-node-label", { [`level-${_ctx.level}`]: true }])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.innerLabelClass),
        onClick: _cache[1] || (_cache[1] = (e) => _ctx.$emit('node-click', e, _ctx.data, _ctx.nodeContext)),
        onMouseout: _cache[2] || (_cache[2] = (e) => _ctx.$emit('node-mouseout', e, _ctx.data, _ctx.nodeContext)),
        onMouseover: _cache[3] || (_cache[3] = (e) => _ctx.$emit('node-mouseover', e, _ctx.data, _ctx.nodeContext)),
        onFocus: _cache[4] || (_cache[4] = (e) => _ctx.$emit('node-focus', e, _ctx.data, _ctx.nodeContext))
      }, [
        _renderSlot(_ctx.$slots, "node", {
          data: _ctx.data,
          context: _ctx.nodeContext
        }, () => [
          (!_ctx.renderContent)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.data[_ctx.props.label]), 1))
            : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.renderContent(_ctx.data)), 1))
        ], true),
        (_ctx.collapsable && !_ctx.isLeaf)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass(_ctx.nodeExpandBtnClass),
              onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onExpandBtnClick && _ctx.onExpandBtnClick(...args)), ["stop"]))
            }, null, 2))
          : _createCommentVNode("", true)
      ], 34)
    ], 2),
    _createVNode(_Transition, {
      name: "expand",
      onEnter: _ctx.enter,
      onAfterEnter: _ctx.afterEnter,
      onLeave: _ctx.leave
    }, {
      default: _withCtx(() => [
        ((_ctx.collapsable && _ctx.expanded && !_ctx.isLeaf) || (!_ctx.collapsable && !_ctx.isLeaf))
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "org-tree-node-children transform",
              id: `org-tree-node-children-${_ctx.data.tree_id}`,
              ref: "childrenLevel"
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data[_ctx.props.children], (ch) => {
                return (_openBlock(), _createBlock(_component_blocks_node, {
                  ref_for: true,
                  ref: "blockNodeRef",
                  key: _ctx.nodeKey(ch),
                  data: ch,
                  props: _ctx.props,
                  activeBusinessUnit: _ctx.activeBusinessUnit,
                  activeBusinessSubunit: _ctx.activeBusinessSubunit,
                  level: _ctx.level + 1,
                  collapsable: _ctx.collapsable,
                  renderContent: _ctx.renderContent,
                  labelClassName: _ctx.labelClassName,
                  onNodeExpand: _cache[5] || (_cache[5] = 
            (e, data, context) => _ctx.$emit('node-expand', e, data, context)
          ),
                  onNodeFocus: _cache[6] || (_cache[6] = 
            (e, data, context) => _ctx.$emit('node-focus', e, data, context)
          ),
                  onNodeClick: _cache[7] || (_cache[7] = 
            (e, data, context) => _ctx.$emit('node-click', e, data, context)
          ),
                  onNodeMouseover: _cache[8] || (_cache[8] = 
            (e, data, context) => _ctx.$emit('node-mouseover', e, data, context)
          ),
                  onNodeMouseout: _cache[9] || (_cache[9] = 
            (e, data, context) => _ctx.$emit('node-mouseout', e, data, context)
          )
                }, {
                  node: _withCtx(({ data, context }) => [
                    _renderSlot(_ctx.$slots, "node", {
                      data: data,
                      context: context
                    }, undefined, true)
                  ]),
                  _: 2
                }, 1032, ["data", "props", "activeBusinessUnit", "activeBusinessSubunit", "level", "collapsable", "renderContent", "labelClassName"]))
              }), 128))
            ], 8, _hoisted_3))
          : _createCommentVNode("", true)
      ]),
      _: 3
    }, 8, ["onEnter", "onAfterEnter", "onLeave"])
  ], 2))
}